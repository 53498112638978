@import "reset";
$primary-color: #ee2c24;
svg {
    display: block;
}
// signal animation
.signal-box {
    width: 60px;
    height: 32px;
    position: absolute;
    &-item {
        position: absolute;
        right: 0;
        left: 0;
        opacity: 0;
        &-1 {
            bottom: 0;
        }
        &-2 {
            top: 9px;
        }
        &-3 {
            top: 0;
        }
    }
    svg {
        margin: 0 auto;
    }
    &.signal-out {
        .signal-box-item {
            animation: signal-wifi 1.5s infinite linear;
        }
        .signal-box-item-1 {
            bottom: 0;
        }
        .signal-box-item-2 {
            top: 9px;
            animation-delay: 0.4s;

        }
        .signal-box-item-3 {
            top: 0;
            animation-delay: 0.7s;
        }
    }
    &.signal-in {
        .signal-box-item {
            animation: signal-wifi 1.5s infinite linear;
        }
        .signal-box-item-1 {
            bottom: 0;
            animation-delay: 0.7s;
        }
        .signal-box-item-2 {
            top: 9px;
            animation-delay: 0.4s;
        }
        .signal-box-item-3 {
            top: 0;
        }
    }
    @media (max-width: 992px) {
        transform: scale(0.7);
    }
}

@keyframes signal-wifi {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.sensor-signal-box {
    position: absolute;
    width: 100px;
    &:before, &:after {
        background: #ffffff;
        width: 7px;
        height: 130px;
        content: "";
        display: block;
        transform-origin: top center;
        position: absolute;
        top: -50px;
        left: 100px;
        z-index: 1;
    }
    &:before {
        top: -52px;
        left: 100px;
        transform: rotate(45deg);
    }
    &:after {
        top: -52px;
        left: -3px;
        transform: rotate(-45deg);
    }
    &-item {
        border: 2px $primary-color solid;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        animation: signal-wifi 1.5s infinite linear;
        opacity: 0;
        &-1 {
            width: 32px;
            height: 32px;
        }
        &-2 {
            width: 65px;
            height: 65px;
            animation-delay: 0.4s;
        }
        &-3 {
            width: 100px;
            height: 100px;
            animation-delay: 0.7s;
        }
    }
    @media (max-width: 1240px) {
        width: 70px;
        &:before {
            top: -45px;
            left: 77px;
        }
        &:after {
            top: -45px;
            left: -12px;
        }
        &-item {
            &-1 {
                width: 18px;
                height: 18px;
            }
            &-2 {
                width: 43px;
                height: 43px;
            }
            &-3 {
                width: 70px;
                height: 70px;
            }
        }
    }
    @media (max-width: 992px) {
        &:before, &:after {
            width: 3px;
        }
        &-item {
            border-width: 0.15em;
            &-1 {
                width: 10px;
                height: 10px;
            }
            &-2 {
                width: 30px;
                height: 30px;
            }
            &-3 {
                width: 50px;
                height: 50px;
            }
        }
    }
}

// progress circle animation
.progress-circle {
    width: 85px;
    height: 15px;
    position: relative;
    &-item {
        position: absolute;
        top: 0;
    }
    &-1 {
        left: 0;
    }
    &-2 {
        left: 50%;
        transform: translateX(-50%);
    }
    &-3 {
        right: 0;
    }
    .progress-circle-inner {
        opacity: 0;
        transition: all 0.2s ease;
    }
    .active {
        .progress-circle-inner {
            opacity: 1;
        }
        .circle-circle-1 {
            stroke: #ed3027;
        }
    }
    @media (max-width: 992px) {
        width: 60px;
        &-item {
            svg {
                width: 10px;
                height: 10px;
            }
        }
    }
}



// wrapper
.animation-section {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 60px auto;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: border-box;
    .desc {
        max-width: 235px;
        width: 100%;
        text-align: left;
        margin: 35px auto 0 auto;
        &.desc-second-color {
            color: #f22719;
            ul {
                li {
                    &:before {
                        border-color: #f22719;
                    }
                }
            }
        }
    }
    h3 {
        font-weight: 700;
        font-size: 16px;
    }
    ul {
        margin-top: 15px;
        li {
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 4px;
            position: relative;
            display: block;
            padding-left: 15px;
            &:before {
                width: 5px;
                height: 5px;
                content: "";
                display: block;
                border: 1px #000000 solid;
                position: absolute;
                top: 7px;
                left: 0;
                border-radius: 50%;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }    
    @media (max-width: 992px) {
        .desc {
            margin-top: 10px;
        }
        ul {
            li {
                font-size: 13px;
            }
        }
    }
    @media (max-width: 768px) {
        .desc {
            margin-top: 20px;
        }
    }
}

.progress-col-1 {
    width: 85px;
    margin-top: 185px;
    margin-right: 2%;
    @media (max-width: 992px) {
        margin-top: 140px;
    }
    @media (max-width: 768px) {
        width: auto;
        order: 2;
        margin: 70px auto 0 auto;
        transform: rotate(90deg);
        transform-origin: top center;
    }
}

.sensors-box {
    width: calc(33.33% - 57px);
    padding-top: 125px;
    position: relative;
    margin-right: 3%;
    .image {
        position: relative;
        svg {
            position: relative;
            z-index: 2;
        }
    }
    .sensor-signal-box {
        top: 7px;
        &-1 {
            left: -1px;
        }
        &-2 {
            left: 119px;
        }
        &-3 {
            left: 238px;
        }
    }
    @media (max-width: 1240px) {
        padding-top: 110px;
        .image {
            width: 275px;
            > svg {
                max-width: 100%;
            }
        }
        .sensor-signal-box {
            top: 20px;
            &-1 {
                left: 5px;
            }
            &-2 {
                left: 103px;
            }
            &-3 {
                left: 201px;
            }
        }
    }
    @media (max-width: 992px) {
        padding-top: 60px;
        .image {
            max-width: 180px;
            margin: 0 auto;
        }
        .sensor-signal-box {
            top: 44px;
            &-1 {
                left: -8px;
            }
            &-2 {
                left: 56px;
            }
            &-3 {
                left: 120px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 1;
        margin-right: 0;
        padding-top: 40px;
        .image {
            padding-top: 40px;
            svg {
                height: auto;
            }
        }
        .signal-box {
            &-1 {
                left: -3px;
            }
            &-2 {
                left: 61px;
            }
            &-3 {
                left: 124px; 
            }
        }
    }
}

.progress-col-2 {
    width: 85px;
    margin-top: 185px;
    .progress-circle {
        transform: rotate(180deg);
        flex-direction: row-reverse;
    }
    @media (max-width: 992px) {
        margin-top: 135px;
        margin-left: 2%;
    }
    @media (max-width: 768px) {
        width: auto;
        order: 4;
        transform: rotate(-90deg);
        transform-origin: top center;
        margin: 0 auto 15px auto;
        position: relative;
        left: -15px;
    }
}

.gateway-box {
    width: calc(23.33% - 57px);
    padding-top: 70px;
    text-align: center;
    margin-top: 105px;
    margin-right: 3%;
    .image {
        display: inline-block;
        position: relative;
    }
    .desc {
        max-width: 175px;
        margin-top: 74px;
    }
    .signal-box-content {
        position: absolute;
        top: 0;
        right: 0;
        &:after {
            width: 8px;
            height: 8px;
            border: 2px $primary-color solid;
            border-radius: 50%;
            display: block;
            content: "";
            position: absolute;
            top: -18px;
            right: 45px;
        }
    }
    .signal-box {
        &:nth-child(1) {
            transform: rotate(-90deg);
            top: -28px;
            right: 50px;
        }
        &:nth-child(2) {
            transform: rotate(90deg);
            top: -28px;
            right: -8px;
        }
    }
    @media (max-width: 1240px) {
        width: calc(21.33% - 57px);
        .image {
            > svg {
                width: 140px;
            }
        }
        .signal-box-content {
            top: 5px;
            right: -11px;
        }
        .desc {
            margin-top: 59px;
        }
    }
    @media (max-width: 992px) {
        margin-top: 55px;
        .image {
            > svg {
                width: 120px;
            }
        }
        .desc {
            margin-top: 35px;
        }
        .signal-box-content {
            transform: scale(0.7);
            top: 10px;
            right: -1px;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 3;
        margin: 0 0 70px 0;
        .image {
            padding-top: 15px;
            > svg {
                height: auto;
            }
        }
        .desc {
            margin-top: 20px;
        }
    }
}

.local-group-box {
    width: calc(33.33% - 57px);
    padding-top: 125px;
    position: relative;
    text-align: right;
    margin-left: 2%;
    .image {
        display: inline-block;
    }
    .signal-box {
        top: 85px;
        &-1 {
            left: 6%;
        }
        &-2 {
            left: 42%;
        }
        &-3 {
            left: 77.5%;
        }
    }
    .arrow-line {
        position: absolute;
        top: 35px;
        animation: arrow-animation 0.6s infinite linear alternate;
        .arrow-line-svg-1 {
            width: 0;
            fill: none; 
            stroke: #ed3228; 
            stroke-miterlimit: 10; 
        } 
        .arrow-line-svg-2 { 
            fill: #ed3228; 
        }
        &-1 {
            left: 70px;
            animation-delay: 0.5s;
        }
        &-2 {
            left: 190px;
        }
    }
    @media (max-width: 1240px) {
        width: calc(35.33% - 57px);
        padding-top: 110px;
        .image {
            width: 275px;
            position: relative;
            > svg {
                max-width: 100%;
            }
        }
        .signal-box {
            top: -22px;
            &-1 {
                right: 205px;
                left: auto;
            }
            &-2 {
                right: 107px;
                left: auto;
            }
            &-3 {
                right: 10px;
                left: auto;
            }
        }
        .arrow-line {
            top: -70px;
            &-1 {
                right: 137px;
                left: auto;
            }
            &-2 {
                right: 40px;
                left: auto;
            }
        }
    }
    @media (max-width: 992px) {
        padding-top: 60px;
        margin-left: 0;
        .image {
            width: 180px;
            margin: 0 auto;
            display: block;
        }
        .signal-box {
            top: 7px;
            &-1 {
                right: 124px;
            }
            &-2 {
                right: 59px;
            }
            &-3 {
                right: -4px;
            }
        }
        .arrow-line {
            top: -27px;
            svg {
                width: 60px;
            }
            &-1 {
                right: 92px;
            }
            &-2 {
                right: 26px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 5;
        .image {
            padding-top: 40px;
            > svg {
                height: auto;
            }
        }
    }
}

@keyframes arrow-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.connection-method-box {
    width: 100%;
    text-align: center;
    margin: 40px 0 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .progress-circle {
        width: auto;
        height: auto;
        position: relative;
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        &-item {
            position: relative;
            padding-left: 20px;
            margin-bottom: 17px;
            svg {
                position: absolute;
                top: -1px;
                left: 0;
            }
        }
    }
    p {
        font-size: 14px;
    }
    @media (max-width: 992px) {
        margin: 40px 0 20px 0;
        p {
            font-size: 13px;
        }
        .progress-circle {
            &-item {
                svg {
                    top: 2px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        order: 6;
    }
}

.management-box {
    width: calc(33.33% - 57px);
    text-align: center;
    .image {
        height: 120px;
        display: inline-block;
    }
    .desc {
        max-width: 185px;
    }
    @media (max-width: 1240px) {
        .image {
            svg {
                width: 140px;
            }
        }
    }
    @media (max-width: 992px) {
        .image {
            svg {
                width: 120px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 9;
        .image {
            height: auto;
            svg {
                height: auto;
            }
        }
        .desc {
            max-width: 235px;
        }
    }
}

.progress-col-3 {
    width: 85px;
    margin-top: 55px;
    .progress-circle {
        transform: rotate(180deg);
        flex-direction: row-reverse;
    }
    @media (max-width: 768px) {
        width: auto;
        order: 8;
        transform: rotate(-90deg);
        transform-origin: top center;
        margin: 70px auto 55px auto;
        position: relative;
        left: -14px;
    }
}

.cloud-box {
    width: calc(33.33% - 57px);
    text-align: center;
    .image {
        height: 120px;
        display: inline-block;
    }
    .desc {
        max-width: 150px;
    }
    @media (max-width: 1240px) {
        .image {
            svg {
                width: 170px;
            }
        }
    }
    @media (max-width: 992px) {
        .image {
            svg {
                width: 140px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 7;
        margin-top: 10px;
        .image {
            height: auto;
            svg {
                height: auto;
            }
        }
        .desc {
            max-width: 235px;
        }
    }
}

.progress-col-4 {
    width: 85px;
    margin-top: 55px;
    @media (max-width: 768px) {
        width: auto;
        order: 10;
        transform: rotate(90deg);
        transform-origin: top center;
        margin: 70px auto 65px auto;
    }
}

.reporting-box {
    width: calc(33.33% - 57px);
    text-align: center;
    .image {
        height: 120px;
        display: inline-block;
    }
    .desc {
        margin-right: 0;
        margin-left: 0;
        float: right;
    }
    @media (max-width: 1240px) {
        .image {
            svg {
                width: 100px;
            }
        }
    }
    @media (max-width: 992px) {
        .image {
            svg {
                width: 90px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        order: 11;
        .image {
            height: auto;
            display: block;
            svg {
                height: auto;
                margin: 0 auto;
            }
        }
        .desc {
            float: none;
            margin: 20px auto 0 auto;
        }
    }
}



























